import { captureException } from "@sentry/react";
import { isCancel } from "axios";
export default function handleReportError(error) {
    console.error(error);
    /**
     * When this happens, it means that the app has new chunks. Just reload everything.
     */ if (/ChunkLoadError/.test(error.name) || /SyntaxError/.test(error.name)) {
        window.location.reload();
        return;
    }
    /**
     * Various scrappers run with jsdom and..this error happens
     */ if (error.message.includes("postMessage is not a function")) {
        return;
    }
    if (isCancel(error) || error.response && [
        401,
        422,
        426,
        403,
        503
    ].includes(error.response.status)) {
        return;
    }
    captureException(error);
}
