/**
 * !!!!!!!!
 * IMPORTANT: DO NOT FORGET TO ADD / REMOVE / EDIT ROUTES HERE IN BOTH PLACES
 * !!!!!!!!
 */ export var routesParts = {
    AUTH_SIGNUP: "signup",
    AUTH_SIGNUP_TERMS_OF_SERVICE: "terms-of-service",
    AUTH_LOGIN: "login",
    AUTH_FORGOT_PASSWORD: "forgot-password",
    AUTH_FORGOT_PASSWORD_FINISH: "forgot-password/finish",
    AUTH_VERIFY_EMAIL: "verify-email",
    DASHBOARD: "",
    PARTNERS: "partners",
    PARTNERS_CREATE: "create",
    PARTNERS_EDIT: "partners/:id",
    PARTNERS_ADDRESSES: "addresses",
    PARTNERS_ADDRESSES_CREATE: "create",
    PARTNERS_ADDRESSES_EDIT: "addresses/:addressId",
    PARTNERS_CONTACTS: "contacts",
    PARTNERS_CONTACTS_CREATE: "create",
    PARTNERS_CONTACTS_EDIT: "contacts/:contactId",
    PARTNERS_BANK_ACCOUNTS: "bank-accounts",
    PARTNERS_BANK_ACCOUNTS_CREATE: "create",
    PARTNERS_BANK_ACCOUNTS_EDIT: "bank-accounts/:bankAccountId",
    PRODUCTS_CATEGORIES: "products-categories",
    PRODUCTS_CATEGORIES_CREATE: "create",
    PRODUCTS_CATEGORIES_EDIT: "products-categories/:productCategoryId",
    PRODUCTS: "products",
    PRODUCTS_CREATE: "create",
    PRODUCTS_EDIT: "products/:productId",
    PRODUCTS_ATTRIBUTES: "attributes",
    PRODUCTS_ATTRIBUTES_CREATE: "create",
    PRODUCTS_ATTRIBUTES_EDIT: "attributes/:attributeId",
    PRODUCTS_PACKAGING: "packaging",
    PRODUCTS_PACKAGING_CREATE: "create",
    PRODUCTS_PACKAGING_EDIT: "packaging/:packagingId",
    PRODUCTS_CLIENTS_OFFERS: "clients-offers",
    PRODUCTS_CLIENTS_OFFERS_CREATE: "create",
    PRODUCTS_CLIENTS_OFFERS_EDIT: "clients-offers/:id",
    PRODUCTS_SUPPLIERS_OFFERS: "suppliers-offers",
    PRODUCTS_SUPPLIERS_OFFERS_CREATE: "create",
    PRODUCTS_SUPPLIERS_OFFERS_EDIT: "suppliers-offers/:id",
    PRODUCTS_CLIENTS_CODES: "clients-codes",
    PRODUCTS_CLIENTS_CODES_CREATE: "create",
    PRODUCTS_CLIENTS_CODES_EDIT: "clients-codes/:id",
    PRODUCTS_SUPPLIERS_CODES: "suppliers-codes",
    PRODUCTS_SUPPLIERS_CODES_CREATE: "create",
    PRODUCTS_SUPPLIERS_CODES_EDIT: "suppliers-codes/:id",
    PRODUCTS_VAT_RATES: "vat-rates",
    PRODUCTS_VAT_RATES_CREATE: "create",
    PRODUCTS_VAT_RATES_EDIT: "vat-rates/:vatRateId",
    PRODUCTS_TRANSLATIONS: "translations",
    PRODUCTS_TRANSLATIONS_CREATE: "create",
    PRODUCTS_TRANSLATIONS_EDIT: "translations/:translationId",
    ARTICLES_REPORTS: "articles/reports",
    CLIENTS_CONTRACTS: "clients/contracts",
    CLIENTS_OFFERS: "clients/offers",
    CLIENTS_ORDERS: "clients/orders",
    CLIENTS_DESPATCHES: "clients/despatches",
    CLIENTS_INVOICES: "clients/invoices",
    CLIENTS_INVOICES_CREATE: "clients/invoices/create",
    CLIENTS_INVOICES_DUPLICATE: "clients/invoices/:invoiceId/duplicate",
    CLIENTS_INVOICES_CREATE_REFUND: "clients/invoices/:invoiceId/refund",
    CLIENTS_INVOICES_CREATE_FROM_PROFORMA: "clients/invoices/create/proforma/:proformaId",
    CLIENTS_INVOICES_CREATE_FROM_CLIENT_GOODS_DESPATCH_ADVICE: "clients/invoices/create/goods-despatch-advice",
    CLIENTS_INVOICES_EDIT: "clients/invoices/:invoiceId",
    CLIENTS_INVOICES_VIEW: "clients/invoices/view/:invoiceId",
    CLIENTS_INVOICES_EFACTURA_MESSAGES: "messages",
    CLIENTS_PROFORMAS_INVOICES: "clients/proformas",
    CLIENTS_PROFORMAS_INVOICES_CREATE: "clients/proformas/create",
    CLIENTS_PROFORMAS_INVOICES_EDIT: "clients/proformas/:proformaId",
    CLIENTS_PROFORMAS_INVOICES_DUPLICATE: "clients/proformas/:proformaId/duplicate",
    CLIENTS_PROFORMAS_INVOICES_VIEW: "clients/proformas/view/:proformaId",
    CLIENTS_PAYMENTS: "clients/payments",
    CLIENTS_PAYMENTS_CREATE: "create",
    CLIENTS_PAYMENTS_EDIT: ":paymentId",
    CLIENTS_RECEIPTS: "clients/receipts",
    CLIENTS_RECEIPTS_CREATE: "create",
    CLIENTS_RECEIPTS_EDIT: ":paymentId",
    CLIENTS_RECEIPTS_VIEW: "clients/receipts/view/:receiptId",
    CLIENTS_REPORTS: "clients/reports",
    CLIENTS_STATEMENT_OF_ACCOUNT_REPORT: "statement-of-account",
    CLIENTS_CURRENT_BALANCE_REPORT: "current-balance",
    CLIENTS_OVERDUE_INVOICES_REPORT: "overdue-invoices",
    CLIENTS_INVOICES_REPORT: "invoices",
    CLIENTS_PAYMENTS_REPORT: "payments",
    SUPPLIERS_CONTRACTS: "suppliers/contracts",
    SUPPLIERS_OFFERS: "suppliers/offers",
    SUPPLIERS_ORDERS: "suppliers/orders",
    SUPPLIERS_DESPATCHES: "suppliers/despatches",
    SUPPLIERS_INVOICES: "suppliers/invoices",
    SUPPLIERS_INVOICES_CREATE: "suppliers/invoices/create",
    SUPPLIERS_INVOICES_EDIT: "suppliers/invoices/:invoiceId",
    SUPPLIERS_INVOICES_VIEW: "suppliers/invoices/view/:invoiceId",
    SUPPLIERS_INVOICES_EFACTURA_MESSAGES: "messages",
    SUPPLIERS_INVOICES_CONFIRM_INVOICE: "confirm",
    SUPPLIERS_INVOICES_REJECT_INVOICE: "reject",
    SUPPLIERS_PAYMENTS: "suppliers/payments",
    SUPPLIERS_PAYMENTS_CREATE: "create",
    SUPPLIERS_PAYMENTS_EDIT: ":paymentId",
    SUPPLIERS_RECEIPTS: "suppliers/receipts",
    SUPPLIERS_RECEIPTS_CREATE: "create",
    SUPPLIERS_RECEIPTS_EDIT: ":receiptId",
    SUPPLIERS_REPORTS: "suppliers/reports",
    SUPPLIERS_STATEMENT_OF_ACCOUNT_REPORT: "statement-of-account",
    SUPPLIERS_CURRENT_BALANCE_REPORT: "current-balance",
    SUPPLIERS_OVERDUE_INVOICES_REPORT: "overdue-invoices",
    SUPPLIERS_INVOICES_REPORT: "invoices",
    SUPPLIERS_PAYMENTS_REPORT: "payments",
    STORE_MANAGEMENT_RECEIPT_NOTES: "store-management/receipt-notes",
    STORE_MANAGEMENT_BONURI_CONSUM: "store-management/bonuri-consum",
    STORE_MANAGEMENT_BONURI_TRANSFER: "store-management/bonuri-transfer",
    STORE_MANAGEMENT_BONURI_AUTORECEPTIE: "store-management/bonuri-autoreceptie",
    STORE_MANAGEMENT_INVENTORY: "store-management/inventory",
    STORE_MANAGEMENT_REPORTS: "store-management/reports",
    SETTINGS_BANKS: "settings/banks",
    SETTINGS_BANKS_CREATE: "create",
    SETTINGS_BANKS_EDIT: "settings/banks/:id",
    SETTINGS_MEASUREMENT_UNITS: "settings/measurement-units",
    SETTINGS_MEASUREMENT_UNITS_CREATE: "create",
    SETTINGS_MEASUREMENT_UNITS_EDIT: "settings/measurement-units/:id",
    SETTINGS_MEASUREMENT_UNITS_TRANSLATIONS: "translations",
    SETTINGS_MEASUREMENT_UNITS_TRANSLATIONS_CREATE: "create",
    SETTINGS_MEASUREMENT_UNITS_TRANSLATIONS_EDIT: "translations/:measurementUnitTranslationId",
    SETTINGS_VAT_RATES: "settings/vat-rates",
    SETTINGS_VAT_RATES_CREATE: "create",
    SETTINGS_VAT_RATES_EDIT: "settings/vat-rates/:id",
    SETTINGS_DOCUMENTS_SERIES: "settings/documents-series",
    SETTINGS_DOCUMENTS_SERIES_CREATE: "create",
    SETTINGS_DOCUMENTS_SERIES_EDIT: "settings/documents-series/:id",
    MANAGEMENT_USERS: "management/users",
    MANAGEMENT_USERS_CREATE: "create",
    MANAGEMENT_USERS_EDIT: "management/users/:id",
    ADMINISTRATION_RO_E_FACTURA_CODES: "administration/ro-e-factura-codes",
    ADMINISTRATION_COUNTRIES_CITIES: "administration/countries-cities",
    ADMINISTRATION_CPV_CODES: "administration/cpv-codes",
    ADMINISTRATION_CURRENCIES: "administration/currencies",
    ADMINISTRATION_MEASUREMENT_UNITS: "administration/measurement-units",
    ADMINISTRATION_TAX_CODES: "administration/tax-codes",
    ADMINISTRATION_USERS: "administration/users",
    MY_ACCOUNT: "my-account",
    MY_ACCOUNT_CHANGE_DETAILS: "change-details",
    MY_ACCOUNT_CHANGE_EMAIL: "change-email",
    MY_ACCOUNT_CHANGE_PASSWORD: "change-password",
    MY_ACCOUNT_START_2FA: "two-factor-authentication",
    MY_ACCOUNT_REGENERATE_2FA: "two-factor-authentication-regenerate",
    MY_ACCOUNT_DEACTIVATE_2FA: "two-factor-authentication-deactivate",
    MY_ACCOUNT_VERIFY_EMAIL: "verify-email",
    MY_ACCOUNT_EXPORTS: "exports",
    CREATE_FIRST_COMPANY: "create-first-company",
    MY_COMPANIES: "my-companies",
    MY_COMPANIES_CREATE: "create",
    MY_COMPANIES_EDIT: "my-companies/:id",
    MY_COMPANIES_ADDRESSES: "addresses",
    MY_COMPANIES_ADDRESSES_CREATE: "create",
    MY_COMPANIES_ADDRESSES_EDIT: "addresses/:addressId",
    MY_COMPANIES_CONTACTS: "contacts",
    MY_COMPANIES_CONTACTS_CREATE: "create",
    MY_COMPANIES_CONTACTS_EDIT: "contacts/:contactId",
    MY_COMPANIES_BANK_ACCOUNTS: "bank-accounts",
    MY_COMPANIES_BANK_ACCOUNTS_CREATE: "create",
    MY_COMPANIES_BANK_ACCOUNTS_EDIT: "bank-accounts/:bankAccountId",
    MY_COMPANIES_SPV: "anaf-spv",
    MY_COMPANIES_SUBSCRIPTIONS: "subscriptions",
    MY_COMPANIES_SPV_ACCOUNTANT: "anaf-spv",
    NOTIFICATIONS_LIST: "notifications",
    // SPV: "spv",
    SPV_EFACTURA: "spv/efactura",
    SPV_ETRANSPORT: "spv/etransport",
    SPV_ETRANSPORT_SUBSCRIPTION: "subscription",
    SPV_ETRANSPORT_TRANSPORT_CONFIRMATION_CREATE: "transport-confirmation/create",
    SPV_ETRANSPORT_TRANSPORT_CONFIRMATION_CREATE_FOR_DECLARATION_ID: ":declarationId",
    SPV_ETRANSPORT_TRANSPORT_CONFIRMATION_EDIT: "transport-confirmation/:id/edit",
    SPV_ETRANSPORT_VEHICLE_MODIFICATION_CREATE: "vehicle-modification/create",
    SPV_ETRANSPORT_VEHICLE_MODIFICATION_CREATE_FOR_DECLARATION_ID: ":declarationId",
    SPV_ETRANSPORT_VEHICLE_MODIFICATION_EDIT: "vehicle-modification/:id/edit",
    SPV_ETRANSPORT_CREATE: "create",
    SPV_ETRANSPORT_CREATE_CLIENT: "spv/etransport/create/client",
    SPV_ETRANSPORT_CREATE_SUPPLIER: "spv/etransport/create/supplier",
    SPV_ETRANSPORT_CREATE_GOODS_DESPATCH_ADVICE: "spv/etransport/create/goods-despatch-advice",
    SPV_ETRANSPORT_EDIT: "spv/etransport/:declarationId",
    SPV_ETRANSPORT_DUPLICATE: "spv/etransport/:declarationId/duplicate",
    SPV_ETRANSPORT_ADD_CORRECTION: "spv/etransport/:declarationId/add-correction",
    SPV_ETRANSPORT_VIEW: "spv/etransport/:declarationId/view",
    INVENTORY_WAREHOUSES: "inventory/warehouses",
    INVENTORY_WAREHOUSES_CREATE: "inventory/warehouses/create",
    INVENTORY_WAREHOUSES_EDIT: "inventory/warehouses/:warehouseId",
    INVENTORY_GOODS_RECEIPT_NOTES: "inventory/goods-receipt-notes",
    INVENTORY_GOODS_RECEIPT_NOTES_CREATE: "inventory/goods-receipt-notes/create",
    INVENTORY_GOODS_RECEIPT_NOTES_CREATE_FROM_SUPPLIER_INVOICE: "inventory/goods-receipt-notes/create/supplier-invoice",
    INVENTORY_GOODS_RECEIPT_NOTES_EDIT: "inventory/goods-receipt-notes/:goodsReceiptNoteId",
    INVENTORY_GOODS_RECEIPT_NOTES_VIEW: "inventory/goods-receipt-notes/view/:goodsReceiptNoteId",
    INVENTORY_GOODS_RECEIPT_NOTES_DUPLICATE: "inventory/goods-receipt-notes/:goodsReceiptNoteId/duplicate",
    INVENTORY_GOODS_RECEIPT_NOTES_REFUND: "inventory/goods-receipt-notes/:goodsReceiptNoteId/refund",
    //???
    INVENTORY_STOCK_RECEPTION_RECEIPTS: "inventory/stock-reception-receipts",
    INVENTORY_STOCK_RECEPTION_RECEIPT_CREATE: "inventory/stock-reception-receipts/create",
    INVENTORY_STOCK_RECEPTION_RECEIPT_EDIT: "inventory/stock-reception-receipts/:stockReceptionReceiptId",
    INVENTORY_STOCK_RECEPTION_RECEIPT_VIEW: "inventory/stock-reception-receipts/view/:stockReceptionReceiptId",
    INVENTORY_STOCK_RECEPTION_RECEIPT_DUPLICATE: "inventory/stock-reception-receipts/:stockReceptionReceiptId/duplicate",
    INVENTORY_STOCK_RECEPTION_RECEIPT_REFUND: "inventory/stock-reception-receipts/:stockReceptionReceiptId/refund",
    INVENTORY_STOCK_CONSUMPTION_RECEIPTS: "inventory/stock-consumption-receipts",
    INVENTORY_STOCK_CONSUMPTION_RECEIPT_CREATE: "inventory/stock-consumption-receipts/create",
    INVENTORY_STOCK_CONSUMPTION_RECEIPT_EDIT: "inventory/stock-consumption-receipts/:stockConsumptionReceiptId",
    INVENTORY_STOCK_CONSUMPTION_RECEIPT_VIEW: "inventory/stock-consumption-receipts/view/:stockConsumptionReceiptId",
    INVENTORY_STOCK_CONSUMPTION_RECEIPT_DUPLICATE: "inventory/stock-consumption-receipts/:stockConsumptionReceiptId/duplicate",
    INVENTORY_STOCK_CONSUMPTION_RECEIPT_REFUND: "inventory/stock-consumption-receipts/:stockConsumptionReceiptId/refund",
    //???
    INVENTORY_CLIENT_GOODS_DESPATCH_ADVICES: "inventory/client-goods-despatch-advices",
    INVENTORY_CLIENT_GOODS_DESPATCH_ADVICES_CREATE: "inventory/client-goods-despatch-advices/create",
    INVENTORY_CLIENT_GOODS_DESPATCH_ADVICES_EDIT: "inventory/client-goods-despatch-advices/:clientGoodsDespatchAdviceId",
    INVENTORY_CLIENT_GOODS_DESPATCH_ADVICES_VIEW: "inventory/client-goods-despatch-advices/:clientGoodsDespatchAdviceId/view",
    INVENTORY_CLIENT_GOODS_DESPATCH_ADVICES_DUPLICATE: "inventory/client-goods-despatch-advices/:clientGoodsDespatchAdviceId/duplicate",
    INVENTORY_CLIENT_GOODS_DESPATCH_ADVICES_REFUND: "inventory/client-goods-despatch-advices/:clientGoodsDespatchAdviceId/refund",
    INVENTORY_STOCKS_REPORTS: "inventory/stocks-reports",
    INVENTORY_STOCKS_REPORTS_CURRENT_STOCK: "current-stock",
    INVENTORY_STOCKS_REPORTS_DATE_CURRENT_STOCK: "date-current-stock",
    INVENTORY_STOCKS_REPORTS_STOCK_BALANCE: "stock-balance",
    INVENTORY_STOCKS_REPORTS_STOCKS_TRANSACTIONS: "stocks-transactions",
    INVENTORY_STOCKS_REPORTS_STOCK_RECORDS: "stock-records"
};
var routes = {
    AUTH_SIGNUP: "/signup",
    AUTH_SIGNUP_TERMS_OF_SERVICE: "/signup/terms-of-service",
    AUTH_LOGIN: "/login",
    AUTH_FORGOT_PASSWORD: "/forgot-password",
    AUTH_FORGOT_PASSWORD_FINISH: "/forgot-password/finish",
    AUTH_VERIFY_EMAIL: "/verify-email",
    DASHBOARD: "/",
    PARTNERS: "/partners",
    PARTNERS_CREATE: "/partners/create",
    PARTNERS_EDIT: "/partners/:id",
    PARTNERS_ADDRESSES: "/partners/:id/addresses",
    PARTNERS_ADDRESSES_CREATE: "/partners/:id/addresses/create",
    PARTNERS_ADDRESSES_EDIT: "/partners/:id/addresses/:addressId",
    PARTNERS_CONTACTS: "/partners/:id/contacts",
    PARTNERS_CONTACTS_CREATE: "/partners/:id/contacts/create",
    PARTNERS_CONTACTS_EDIT: "/partners/:id/contacts/:contactId",
    PARTNERS_BANK_ACCOUNTS: "/partners/:id/bank-accounts",
    PARTNERS_BANK_ACCOUNTS_CREATE: "/partners/:id/bank-accounts/create",
    PARTNERS_BANK_ACCOUNTS_EDIT: "/partners/:id/bank-accounts/:bankAccountId",
    PRODUCTS_CATEGORIES: "/products-categories",
    PRODUCTS_CATEGORIES_CREATE: "/products-categories/create",
    PRODUCTS_CATEGORIES_EDIT: "/products-categories/:productCategoryId",
    PRODUCTS: "/products",
    PRODUCTS_CREATE: "/products/create",
    PRODUCTS_EDIT: "/products/:productId",
    PRODUCTS_ATTRIBUTES: "/products/:productId/attributes",
    PRODUCTS_ATTRIBUTES_CREATE: "/products/:productId/attributes/create",
    PRODUCTS_ATTRIBUTES_EDIT: "/products/:productId/attributes/:attributeId",
    PRODUCTS_PACKAGING: "/products/:productId/packaging",
    PRODUCTS_PACKAGING_CREATE: "/products/:productId/packaging/create",
    PRODUCTS_PACKAGING_EDIT: "/products/:productId/packaging/:packagingId",
    PRODUCTS_CLIENTS_OFFERS: "/products/:productId/clients-offers",
    PRODUCTS_CLIENTS_OFFERS_CREATE: "/products/:productId/clients-offers/create",
    PRODUCTS_CLIENTS_OFFERS_EDIT: "/products/:productId/clients-offers/:id",
    PRODUCTS_SUPPLIERS_OFFERS: "/products/:productId/suppliers-offers",
    PRODUCTS_SUPPLIERS_OFFERS_CREATE: "/products/:productId/suppliers-offers/create",
    PRODUCTS_SUPPLIERS_OFFERS_EDIT: "/products/:productId/suppliers-offers/:id",
    PRODUCTS_CLIENTS_CODES: "/products/:productId/clients-codes",
    PRODUCTS_CLIENTS_CODES_CREATE: "/products/:productId/clients-codes/create",
    PRODUCTS_CLIENTS_CODES_EDIT: "/products/:productId/clients-codes/:id",
    PRODUCTS_SUPPLIERS_CODES: "/products/:productId/suppliers-codes",
    PRODUCTS_SUPPLIERS_CODES_CREATE: "/products/:productId/suppliers-codes/create",
    PRODUCTS_SUPPLIERS_CODES_EDIT: "/products/:productId/suppliers-codes/:id",
    PRODUCTS_VAT_RATES: "/products/:productId/vat-rates",
    PRODUCTS_VAT_RATES_CREATE: "/products/:productId/vat-rates/create",
    PRODUCTS_VAT_RATES_EDIT: "/products/:productId/vat-rates/:vatRateId",
    PRODUCTS_TRANSLATIONS: "/products/:productId/translations",
    PRODUCTS_TRANSLATIONS_CREATE: "/products/:productId/translations/create",
    PRODUCTS_TRANSLATIONS_EDIT: "/products/:productId/translations/:translationId",
    ARTICLES_REPORTS: "/articles/reports",
    CLIENTS_CONTRACTS: "/clients/contracts",
    CLIENTS_OFFERS: "/clients/offers",
    CLIENTS_ORDERS: "/clients/orders",
    CLIENTS_DESPATCHES: "/clients/despatches",
    CLIENTS_INVOICES: "/clients/invoices",
    CLIENTS_INVOICES_CREATE: "/clients/invoices/create",
    CLIENTS_INVOICES_DUPLICATE: "/clients/invoices/:invoiceId/duplicate",
    CLIENTS_INVOICES_CREATE_REFUND: "/clients/invoices/:invoiceId/refund",
    CLIENTS_INVOICES_CREATE_FROM_PROFORMA: "/clients/invoices/create/proforma/:proformaId",
    CLIENTS_INVOICES_CREATE_FROM_CLIENT_GOODS_DESPATCH_ADVICE: "/clients/invoices/create/goods-despatch-advice",
    CLIENTS_INVOICES_EDIT: "/clients/invoices/:invoiceId",
    CLIENTS_INVOICES_VIEW: "/clients/invoices/view/:invoiceId",
    CLIENTS_INVOICES_EFACTURA_MESSAGES: "/clients/invoices/view/:invoiceId/messages",
    CLIENTS_PROFORMAS_INVOICES: "/clients/proformas",
    CLIENTS_PROFORMAS_INVOICES_CREATE: "/clients/proformas/create",
    CLIENTS_PROFORMAS_INVOICES_EDIT: "/clients/proformas/:proformaId",
    CLIENTS_PROFORMAS_INVOICES_DUPLICATE: "/clients/proformas/:proformaId/duplicate",
    CLIENTS_PROFORMAS_INVOICES_VIEW: "/clients/proformas/view/:proformaId",
    CLIENTS_PAYMENTS: "/clients/payments",
    CLIENTS_PAYMENTS_CREATE: "/clients/payments/create",
    CLIENTS_PAYMENTS_EDIT: "/clients/payments/:paymentId",
    CLIENTS_RECEIPTS: "/clients/receipts",
    CLIENTS_RECEIPTS_CREATE: "/clients/receipts/create",
    CLIENTS_RECEIPTS_VIEW: "/clients/receipts/view/:receiptId",
    CLIENTS_RECEIPTS_EDIT: "/clients/receipts/:paymentId",
    CLIENTS_REPORTS: "/clients/reports",
    CLIENTS_STATEMENT_OF_ACCOUNT_REPORT: "/clients/reports/statement-of-account",
    CLIENTS_CURRENT_BALANCE_REPORT: "/clients/reports/current-balance",
    CLIENTS_OVERDUE_INVOICES_REPORT: "/clients/reports/overdue-invoices",
    CLIENTS_INVOICES_REPORT: "/clients/reports/invoices",
    CLIENTS_PAYMENTS_REPORT: "/clients/reports/payments",
    SUPPLIERS_CONTRACTS: "/suppliers/contracts",
    SUPPLIERS_OFFERS: "/suppliers/offers",
    SUPPLIERS_ORDERS: "/suppliers/orders",
    SUPPLIERS_DESPATCHES: "/suppliers/despatches",
    SUPPLIERS_INVOICES: "/suppliers/invoices",
    SUPPLIERS_INVOICES_CREATE: "/suppliers/invoices/create",
    SUPPLIERS_INVOICES_EDIT: "/suppliers/invoices/:invoiceId",
    SUPPLIERS_INVOICES_VIEW: "/suppliers/invoices/view/:invoiceId",
    SUPPLIERS_INVOICES_EFACTURA_MESSAGES: "/suppliers/invoices/view/:invoiceId/messages",
    SUPPLIERS_INVOICES_CONFIRM_INVOICE: "/suppliers/invoices/view/:invoiceId/confirm",
    SUPPLIERS_INVOICES_REJECT_INVOICE: "/suppliers/invoices/view/:invoiceId/reject",
    SUPPLIERS_PAYMENTS: "/suppliers/payments",
    SUPPLIERS_PAYMENTS_CREATE: "/suppliers/payments/create",
    SUPPLIERS_PAYMENTS_EDIT: "/suppliers/payments/:paymentId",
    SUPPLIERS_RECEIPTS: "/suppliers/receipts",
    SUPPLIERS_RECEIPTS_CREATE: "/suppliers/receipts/create",
    SUPPLIERS_RECEIPTS_EDIT: "/suppliers/receipts/:receiptId",
    SUPPLIERS_REPORTS: "/suppliers/reports",
    SUPPLIERS_STATEMENT_OF_ACCOUNT_REPORT: "/suppliers/reports/statement-of-account",
    SUPPLIERS_CURRENT_BALANCE_REPORT: "/suppliers/reports/current-balance",
    SUPPLIERS_OVERDUE_INVOICES_REPORT: "/suppliers/reports/overdue-invoices",
    SUPPLIERS_INVOICES_REPORT: "/suppliers/reports/invoices",
    SUPPLIERS_PAYMENTS_REPORT: "/suppliers/reports/payments",
    STORE_MANAGEMENT_GOODS_RECEIPT_NOTES: "/store-management/goods-receipt-note",
    STORE_MANAGEMENT_CONSUMPTION_RECEIPT_NOTES: "/store-management/consumption-receipt-notes",
    STORE_MANAGEMENT_TRANSFER_RECEIPT_NOTES: "/store-management/transfer-receipt-notes",
    STORE_MANAGEMENT_AUTO_RECEIPT_NOTES: "/store-management/auto-receipt-notes",
    STORE_MANAGEMENT_INVENTORY: "/store-management/inventory",
    STORE_MANAGEMENT_REPORTS: "/store-management/reports",
    SETTINGS_BANKS: "/settings/banks",
    SETTINGS_BANKS_CREATE: "/settings/banks/create",
    SETTINGS_BANKS_EDIT: "/settings/banks/:id",
    SETTINGS_MEASUREMENT_UNITS: "/settings/measurement-units",
    SETTINGS_MEASUREMENT_UNITS_CREATE: "/settings/measurement-units/create",
    SETTINGS_MEASUREMENT_UNITS_EDIT: "/settings/measurement-units/:id",
    SETTINGS_MEASUREMENT_UNITS_TRANSLATIONS: "/settings/measurement-units/:id/translations",
    SETTINGS_MEASUREMENT_UNITS_TRANSLATIONS_CREATE: "/settings/measurement-units/:id/translations/create",
    SETTINGS_MEASUREMENT_UNITS_TRANSLATIONS_EDIT: "/settings/measurement-units/:id/translations/:measurementUnitTranslationId",
    SETTINGS_VAT_RATES: "/settings/vat-rates",
    SETTINGS_VAT_RATES_CREATE: "/settings/vat-rates/create",
    SETTINGS_VAT_RATES_EDIT: "/settings/vat-rates/:id",
    SETTINGS_DOCUMENTS_SERIES: "/settings/documents-series",
    SETTINGS_DOCUMENTS_SERIES_CREATE: "/settings/documents-series/create",
    SETTINGS_DOCUMENTS_SERIES_EDIT: "/settings/documents-series/:id",
    MANAGEMENT_USERS: "/management/users",
    MANAGEMENT_USERS_CREATE: "/management/users/create",
    MANAGEMENT_USERS_EDIT: "/management/users/:id",
    ADMINISTRATION_RO_E_FACTURA_CODES: "/administration/ro-e-factura-codes",
    ADMINISTRATION_COUNTRIES_CITIES: "/administration/countries-cities",
    ADMINISTRATION_CPV_CODES: "/administration/cpv-codes",
    ADMINISTRATION_CURRENCIES: "/administration/currencies",
    ADMINISTRATION_MEASUREMENT_UNITS: "/administration/measurement-units",
    ADMINISTRATION_TAX_CODES: "/administration/tax-codes",
    ADMINISTRATION_USERS: "/administration/users",
    MY_ACCOUNT: "/my-account",
    MY_ACCOUNT_CHANGE_DETAILS: "/my-account/change-details",
    MY_ACCOUNT_CHANGE_EMAIL: "/my-account/change-email",
    MY_ACCOUNT_CHANGE_PASSWORD: "/my-account/change-password",
    MY_ACCOUNT_START_2FA: "/my-account/two-factor-authentication",
    MY_ACCOUNT_REGENERATE_2FA: "/my-account/two-factor-authentication-regenerate",
    MY_ACCOUNT_DEACTIVATE_2FA: "/my-account/two-factor-authentication-deactivate",
    MY_ACCOUNT_VERIFY_EMAIL: "/my-account/verify-email",
    MY_ACCOUNT_EXPORTS: "/my-account/exports",
    CREATE_FIRST_COMPANY: "/create-first-company",
    MY_COMPANIES: "/my-companies",
    MY_COMPANIES_CREATE: "/my-companies/create",
    MY_COMPANIES_EDIT: "/my-companies/:id",
    MY_COMPANIES_ADDRESSES: "/my-companies/:id/addresses",
    MY_COMPANIES_ADDRESSES_CREATE: "/my-companies/:id/addresses/create",
    MY_COMPANIES_ADDRESSES_EDIT: "/my-companies/:id/addresses/:addressId",
    MY_COMPANIES_CONTACTS: "/my-companies/:id/contacts",
    MY_COMPANIES_CONTACTS_CREATE: "/my-companies/:id/contacts/create",
    MY_COMPANIES_CONTACTS_EDIT: "/my-companies/:id/contacts/:contactId",
    MY_COMPANIES_BANK_ACCOUNTS: "/my-companies/:id/bank-accounts",
    MY_COMPANIES_BANK_ACCOUNTS_CREATE: "/my-companies/:id/bank-accounts/create",
    MY_COMPANIES_BANK_ACCOUNTS_EDIT: "/my-companies/:id/bank-accounts/:bankAccountId",
    MY_COMPANIES_SPV: "/my-companies/:id/anaf-spv",
    MY_COMPANIES_SUBSCRIPTIONS: "/my-companies/:id/subscriptions",
    MY_COMPANIES_SPV_ACCOUNTANT: "/anaf-spv",
    NOTIFICATIONS_LIST: "/notifications",
    SPV: "/spv",
    SPV_EFACTURA: "/spv/efactura",
    SPV_ETRANSPORT: "/spv/etransport",
    SPV_ETRANSPORT_SUBSCRIPTION: "/spv/etransport/subscription",
    __SPV_ETRANSPORT_TRANSPORT_CONFIRMATION: "/spv/etransport/transport-confirmation",
    SPV_ETRANSPORT_TRANSPORT_CONFIRMATION_CREATE: "/spv/etransport/transport-confirmation/create",
    SPV_ETRANSPORT_TRANSPORT_CONFIRMATION_CREATE_FOR_DECLARATION_ID: "/spv/etransport/transport-confirmation/create/:declarationId",
    SPV_ETRANSPORT_TRANSPORT_CONFIRMATION_EDIT: "/spv/etransport/transport-confirmation/:id/edit",
    __SPV_ETRANSPORT_VEHICLE_MODIFICATION: "/spv/etransport/vehicle-modification",
    SPV_ETRANSPORT_VEHICLE_MODIFICATION_CREATE: "/spv/etransport/vehicle-modification/create",
    SPV_ETRANSPORT_VEHICLE_MODIFICATION_CREATE_FOR_DECLARATION_ID: "/spv/etransport/vehicle-modification/create/:declarationId",
    SPV_ETRANSPORT_VEHICLE_MODIFICATION_EDIT: "/spv/etransport/vehicle-modification/:id/edit",
    SPV_ETRANSPORT_CREATE: "/spv/etransport/create",
    SPV_ETRANSPORT_CREATE_CLIENT: "/spv/etransport/create/client",
    SPV_ETRANSPORT_CREATE_SUPPLIER: "/spv/etransport/create/supplier",
    SPV_ETRANSPORT_CREATE_GOODS_DESPATCH_ADVICE: "/spv/etransport/create/goods-despatch-advice",
    SPV_ETRANSPORT_EDIT: "/spv/etransport/:declarationId",
    SPV_ETRANSPORT_DUPLICATE: "/spv/etransport/:declarationId/duplicate",
    SPV_ETRANSPORT_ADD_CORRECTION: "/spv/etransport/:declarationId/add-correction",
    SPV_ETRANSPORT_VIEW: "/spv/etransport/:declarationId/view",
    INVENTORY_WAREHOUSES: "/inventory/warehouses",
    INVENTORY_WAREHOUSES_CREATE: "/inventory/warehouses/create",
    INVENTORY_WAREHOUSES_EDIT: "/inventory/warehouses/:warehouseId",
    INVENTORY_GOODS_RECEIPT_NOTES: "/inventory/goods-receipt-notes",
    INVENTORY_GOODS_RECEIPT_NOTES_CREATE: "/inventory/goods-receipt-notes/create",
    INVENTORY_GOODS_RECEIPT_NOTES_CREATE_FROM_SUPPLIER_INVOICE: "/inventory/goods-receipt-notes/create/supplier-invoice",
    INVENTORY_GOODS_RECEIPT_NOTES_EDIT: "/inventory/goods-receipt-notes/:goodsReceiptNoteId",
    INVENTORY_GOODS_RECEIPT_NOTES_VIEW: "/inventory/goods-receipt-notes/view/:goodsReceiptNoteId",
    INVENTORY_GOODS_RECEIPT_NOTES_DUPLICATE: "/inventory/goods-receipt-notes/:goodsReceiptNoteId/duplicate",
    INVENTORY_GOODS_RECEIPT_NOTES_REFUND: "/inventory/goods-receipt-notes/:goodsReceiptNoteId/refund",
    //???
    INVENTORY_STOCK_RECEPTION_RECEIPT: "/inventory/stock-reception-receipts",
    INVENTORY_STOCK_RECEPTION_RECEIPT_CREATE: "/inventory/stock-reception-receipts/create",
    INVENTORY_STOCK_RECEPTION_RECEIPT_EDIT: "/inventory/stock-reception-receipts/:stockReceptionReceiptId",
    INVENTORY_STOCK_RECEPTION_RECEIPT_VIEW: "/inventory/stock-reception-receipts/view/:stockReceptionReceiptId",
    INVENTORY_STOCK_RECEPTION_RECEIPT_DUPLICATE: "/inventory/stock-reception-receipts/:stockReceptionReceiptId/duplicate",
    INVENTORY_STOCK_RECEPTION_RECEIPT_REFUND: "/inventory/stock-reception-receipts/:stockReceptionReceiptId/refund",
    INVENTORY_STOCK_CONSUMPTION_RECEIPTS: "/inventory/stock-consumption-receipts",
    INVENTORY_STOCK_CONSUMPTION_RECEIPTS_CREATE: "/inventory/stock-consumption-receipts/create",
    INVENTORY_STOCK_CONSUMPTION_RECEIPTS_EDIT: "/inventory/stock-consumption-receipts/:stockConsumptionReceiptId",
    INVENTORY_STOCK_CONSUMPTION_RECEIPTS_VIEW: "/inventory/stock-consumption-receipts/view/:stockConsumptionReceiptId",
    INVENTORY_STOCK_CONSUMPTION_RECEIPTS_DUPLICATE: "/inventory/stock-consumption-receipts/:stockConsumptionReceiptId/duplicate",
    INVENTORY_STOCK_CONSUMPTION_RECEIPTS_REFUND: "/inventory/stock-consumption-receipts/:stockConsumptionReceiptId/refund",
    //???
    INVENTORY_CLIENT_GOODS_DESPATCH_ADVICES: "/inventory/client-goods-despatch-advices",
    INVENTORY_CLIENT_GOODS_DESPATCH_ADVICES_CREATE: "/inventory/client-goods-despatch-advices/create",
    INVENTORY_CLIENT_GOODS_DESPATCH_ADVICES_EDIT: "/inventory/client-goods-despatch-advices/:clientGoodsDespatchAdviceId",
    INVENTORY_CLIENT_GOODS_DESPATCH_ADVICES_VIEW: "/inventory/client-goods-despatch-advices/:clientGoodsDespatchAdviceId/view",
    INVENTORY_CLIENT_GOODS_DESPATCH_ADVICES_DUPLICATE: "/inventory/client-goods-despatch-advices/:clientGoodsDespatchAdviceId/duplicate",
    INVENTORY_CLIENT_GOODS_DESPATCH_ADVICES_REFUND: "/inventory/client-goods-despatch-advices/:clientGoodsDespatchAdviceId/refund",
    INVENTORY_STOCKS_REPORTS: "/inventory/stocks-reports",
    INVENTORY_STOCKS_REPORTS_CURRENT_STOCK: "/inventory/stocks-reports/current-stock",
    INVENTORY_STOCKS_REPORTS_DATE_CURRENT_STOCK: "/inventory/stocks-reports/date-current-stock",
    INVENTORY_STOCKS_REPORTS_STOCK_BALANCE: "/inventory/stocks-reports/stock-balance",
    INVENTORY_STOCKS_REPORTS_STOCKS_TRANSACTIONS: "/inventory/stocks-reports/stocks-transactions",
    INVENTORY_STOCKS_REPORTS_STOCK_RECORDS: "/inventory/stocks-reports/stock-records"
};
export default routes;
